import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useVideoStore } from '../../store/';
import '../../pages/references/references.style.scss';


const VideoPlayer = () => {
    const { videos, currentVideo, preloadVideo } = useVideoStore();

    useEffect(() => {

        if (currentVideo) {
            preloadVideo(currentVideo);
        }
    }, [currentVideo, preloadVideo]);

    useEffect(() => {
       
    }, [videos]);

    const videoData = videos.find((video) => video.id === currentVideo);

    return (
        <div>
            {videoData ? (
                <ReactPlayer
                    className="videoPlayer"
                    playing
                    controls
                    muted
                    url={videoData.url}
                />
            ) : (
                <p>Video error.</p>
            )}
        </div>
    );
};

export default VideoPlayer;
