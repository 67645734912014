import create from 'zustand';

interface VideoStore {
    videos: { id: string; url: string; preloaded: boolean }[];
    currentVideo: string | null;
    preloadVideo: (id: string) => void;
    setCurrentVideo: (id: string) => void;
}

export const useVideoStore = create<VideoStore>((set) => ({
    videos: [
        { id: 'video1', url: 'https://tesenergy.hr/videos/Projekt1.mp4', preloaded: false },
        // { id: 'video2', url: process.env.PUBLIC_URL + '/videos/Projekt2.mp4', preloaded: false },
        // { id: 'video3', url: process.env.PUBLIC_URL + '/videos/Projekt3.mp4', preloaded: false },
    ],
    currentVideo: null,
    preloadVideo: (id) =>
        set((state) => ({
            videos: state.videos.map((video) =>
                video.id === id ? { ...video, preloaded: true } : video
            ),
        }))
    ,
    setCurrentVideo: (id) => set(() => ({ currentVideo: id })),
}));

export default useVideoStore