import { ParagraphComponent, SubtitleComponent, ButtonComponent, ModalComponent, ProductModalComponent } from '../';
import './productCard.style.scss'
import { Filter, ActiveFilters } from '../../pages/chargers/filters'
import { CategoryEquipment } from '../../pages/equipment/categories';
import { CategoryChargers } from '../../pages/chargers/categories'
import { Trans, useTranslation } from 'react-i18next';
import { JsxFlags } from 'typescript';
import { useEffect, useState } from 'react';
import { Product, data } from './data';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import imagePlaceholder from '../../assets/images/imagePlaceholder.svg';
import { NavLink } from 'react-router-dom';

interface Props {
    data: Array<Product>;
    chargers?: boolean;
    // categories: CategoryEquipment | CategoryChargers;
    electro?: boolean;
    automatization?: boolean;
    filteredData?: Product[];
    style?: any;
    className?: any;
    onClick?: any;
}

export default function ProductCardComponent(props: Props) {
    const { data, style, className, onClick } = props;
    const { t, i18n } = useTranslation();

    let categoriesEquipment: CategoryEquipment = t('solarEquipment.categories')
    let categoriesChargers: CategoryChargers = t('chargersEquipment.categories')

    const [openModal, setOpenModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const location = useLocation()
    const navigate = useNavigate();

    function renderCategory(currentCategory: string) {
        // if (chargers) {
        //     if (currentCategory as keyof CategoryChargers) {
        //         return (categoriesChargers[currentCategory as keyof CategoryChargers]);
        //     }
        // }

        if (currentCategory as keyof CategoryEquipment) {
            return (categoriesEquipment[currentCategory as keyof CategoryEquipment]);
        }
        else return ('');
    }

    let returnLink = true

    return (<>
        <ProductModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} selectedProduct={selectedProduct} />
        <Trans>
            {/* await Promise.all( */}
            {
                data.map((val) => { //Equipment start      
                    return (
                        <div key={val.id} onClick={() => {
                            setSelectedProduct(val);
                            // navigate("/" + i18n.language + t('navbar.routes.equipment') + "/" + val.ref, { state: { returnLink: returnLink, id: val.id } })
                        }} style={style} className={`productCard ${className} `}>
                            <div className={`imgBackground ${val.image === undefined ? 'placeholderBg' : 'noImgBg'}`}> {val.image === undefined ? <img src={imagePlaceholder} alt={`Placeholder image`} /> : <img src={val.image} alt={`Product image of ${val.title}`} />}</div>
                            <div className="productCardContent">
                                <div className="productCardHeader">
                                    <h2><SubtitleComponent className='productTitle' white>{val.title}</SubtitleComponent></h2>
                                    <ParagraphComponent className='productBrand' >{val.categoryUrl ? renderCategory(val.categoryUrl) : val.category} {val.brand ? '-' : null} {val.brand}</ParagraphComponent>
                                </div>
                                <div className="productCardFooter">
                                    {/* <h3><SubtitleComponent className={`productPrice ${val.stock === 0 && 'outOfStockPrice'}`}>{val.price?.toFixed(2).toString().replace(".", ",")} &euro;</SubtitleComponent></h3> */}
                                    <NavLink to={"/" + i18n.language + t('navbar.routes.equipment') + "/" + val.ref} state={{ returnLink: returnLink, id: val.id }}>
                                        <ButtonComponent style={{ marginTop: '1rem' }} onClick={() => {
                                            setSelectedProduct(val);
                                            // setOpenModal(true);
                                            //  navigate(location.pathname + "/" +val.id) 
                                        }}>{t('productsCard.details')}</ButtonComponent>
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                    )
                }
                )
            }
            {/* ) */}

        </Trans></>);


}
