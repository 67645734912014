import React, { useEffect, useState } from 'react'
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../components';
import './chargers.styles.scss';
import ProductCardComponent from '../../components/productCard/productCard.component';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//@ts-ignore;
import arrowDownGrey from '../../assets/icons/arrowDownGrey.svg';
import { sortProductsByStock } from '../equipment/Equipment';
import { Product } from '../../components/productCard/data';
import { decode } from 'html-entities';
import { NavLink } from 'react-router-dom';
import useProductStore from '../../store/productStore';
import { useMediaQuery } from 'react-responsive';


function Chargers() {
  const { t, i18n } = useTranslation();
  const { setLoading, chargers, charger_accessories, loading, fetchChargers, fetchChargerAccessories, error } = useProductStore();

  const [tempChargers, setTempChargers] = useState<Product[]>([]);

  const [accessories, setAccessories] = useState<Product[]>([]);
  const [tempAccessories, setTempAccessories] = useState<Product[]>([]);

  const [numberOfProducts, setNumberOfProducts] = useState<number>(4);
  const isLargeDesktop = useMediaQuery({ minWidth: 1500 });

  useEffect(() => {
    setLoading(true)
    if (chargers.products.length === 0) fetchChargers(0)
    if (charger_accessories.products.length === 0) fetchChargerAccessories(0)
  }, [])

  useEffect(() => {
    const sortedProducts = sortProductsByStock(chargers.products);
    setTempChargers(sortedProducts);
    const sortedProductsAccessories = sortProductsByStock(charger_accessories.products);
    setTempAccessories(sortedProductsAccessories);
    setLoading(false)
  }, [chargers, charger_accessories]);

  useEffect(() => {
    if (isLargeDesktop) setNumberOfProducts(7)
  }, [isLargeDesktop]);


  return (
    <div className='chargers'>
      <Helmet>
        <title>{t('chargersEquipment.meta.title')}</title>
        <meta name="description" content={t('chargersEquipment.meta.description')} />
      </Helmet>
      <ContainerComponent>
        <Trans>
          <h1><TitleComponent smaller centered>{t('chargersEquipment.title')}</TitleComponent></h1>
          <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>
        </Trans>

        <h3><SubtitleComponent semiBold white medium style={{ marginTop: '3rem' }}>{t('chargersEquipment.categoriesDropdown.chargers')}</SubtitleComponent></h3>
        <div className="productCardContainer">
          {loading ? (
            <>
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
            </>
          ) : tempChargers.length > 0 ?
            (
              isLargeDesktop ? <ProductCardComponent chargers={false} data={tempChargers.slice(0, numberOfProducts)} /> : <ProductCardComponent chargers={false} data={tempChargers.slice(0, 4)} />
            )
            : (
              ''
            )}
          {error && <ParagraphComponent>ERROR: {error.message}</ParagraphComponent>}

        </div>
        < NavLink to={t('chargersEquipment.urlCategoriesDropdown.chargers')}><SubtitleComponent green centered semiBold white style={{ marginTop: '3rem' }}>{t('chargersEquipment.moreProducts.chargers')}</SubtitleComponent></NavLink>
        <h3><SubtitleComponent semiBold white medium style={{ marginTop: '3rem' }}>{t('chargersEquipment.categoriesDropdown.chargerAccessories')}</SubtitleComponent></h3>
        <div className="productCardContainer">
          {loading ? (
            <>
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
              <ProductCardSkeletonComponent />
            </>
          ) : tempAccessories.length > 0 ?
            (
              isLargeDesktop ? <ProductCardComponent chargers={false} data={tempAccessories.slice(0, numberOfProducts)} /> : <ProductCardComponent chargers={false} data={tempAccessories.slice(0, 4)} />
            )
            : (
              ''
            )}
          {error && <ParagraphComponent>ERROR: {error.message}</ParagraphComponent>}

        </div>
        <NavLink to={t('chargersEquipment.urlCategoriesDropdown.chargerAccessories')}><SubtitleComponent green centered semiBold white style={{ marginTop: '3rem' }}>{t('chargersEquipment.moreProducts.chargerAccessories')}</SubtitleComponent></NavLink>
      </ContainerComponent>
    </div >
  )
}

export default (Chargers);