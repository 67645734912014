import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import './navbar.style.scss';
import { ButtonComponent, EnquiryModalComponent, ModalComponent, ParagraphComponent, ProductModalComponent, SearchBarComponent, SubtitleComponent } from '../';
import { useTranslation, withTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

import Flag from 'react-flagpack'

import logo from '../../assets/images/logo-white.svg';
import mailIcon from '../../assets/icons/mailIcon.png';
import cartIcon from '../../assets/icons/cart.svg';
import arrowDown from '../../assets/icons/arrowDown.png';
import closeMenu from '../../assets/icons/closeMenu.png';
import closeIcon from '../../assets/icons/closeIcon.png';
import plusIcon from '../../assets/icons/plus.svg';
import minusIcon from '../../assets/icons/minus.svg';

import i18n from '../../i18n';
import { Product } from '../productCard/data';
import useCartStore from '../../store/cartStore';
import DropdownComponent from './dropdown.component';
import { useMediaQuery } from 'react-responsive';
import { useVideoStore } from '../../store';


function NavbarComponent({ t }: { t: any }) {

  const [click, setClick] = useState(false)
  const [clickSearch, setClickSearch] = useState(false)
  const [clickSearchMobile, setClickSearchMobile] = useState(false)
  const [closeMobile, setCloseMobile] = useState(false)
  const [navbar, setNavbar] = useState(false)
  const [lang, setLang] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openProductModal, setOpenProductModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [openEnquiryModal, setOpenEnquiryModal] = useState(false)
  const [expandedCart, setExpandedCart] = useState(false);
  let totalPrice = 0;
  const navRef = useRef<HTMLDivElement>(null);
  const cartRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef<HTMLDivElement>(null);
  const navLinksRef = useRef<HTMLDivElement>(null);
  const { cart, updateQuantity, removeFromCart, toggleCart, setToogleCart } = useCartStore();
  const navigate = useNavigate();

  const [noProductsInCart, setNoProductsInCart] = useState(0)

  const setCurrentVideo = useVideoStore((state) => state.setCurrentVideo);
  const videos = useVideoStore((state) => state.videos);

  const isLargeDesktop = useMediaQuery({ maxWidth: 768 });


  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  }, [])

  useEffect(() => {
    setNoProductsInCart(cart.length)
  }, [cart])

  useEffect(() => {
    const storedData = localStorage.getItem('lng');
    if (storedData) {
      setLang(false)
    } else setLang(true)
  }, [changePageLanguage])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (cartRef.current && cartRef.current.contains(event.target as Node)) {
        setToogleCart(true)
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuIconRef.current && menuIconRef.current.contains(event.target as Node)) {

      }
      else if (navLinksRef.current && navLinksRef.current.contains(event.target as Node)) {
        // setClick(false);

      } else if (navRef.current && navRef.current.contains(event.target as Node)) {
        setClick(true);


      }
      else if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setClick(false);
      }


    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  function changeProductQuantity(productId: string, quantity: number) {
    updateQuantity(productId, quantity);
  }

  function removeProductFromCart(productId: string) {
    removeFromCart(productId)
  }

  const toggleCartExpansion = () => {
    setToogleCart(toggleCart);
  };


  function changePageLanguage() {
    localStorage.setItem("lng", i18n.resolvedLanguage)
  }

  function changeBackground() {
    if (window.scrollY >= 50) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    setClick(false)
  }, [closeMobile])

  return (
    <>
      <ProductModalComponent openModal={openProductModal} closeModal={() => setOpenProductModal(false)} selectedProduct={selectedProduct} />
      <ModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} />
      <EnquiryModalComponent openModal={openEnquiryModal} closeModal={() => setOpenEnquiryModal(false)} />
      <div className="navWithLang">
        <nav className={navbar && !lang ? "navbar scrolled noTop" : navbar && lang ? "navbar scrolled noTop" : !navbar && !lang ? "navbar noTop" : "navbar noTop"}>
          {/* Mobile navbar */}
          <div ref={navRef} className={`extendedNavbar ${click ? 'change' : ''} `}>
            <div ref={navLinksRef} className={click ? 'showLinks' : 'hideLinks'}>
              <SearchBarComponent clickSearchMobile={clickSearchMobile} setClickSearchMobile={setClickSearchMobile} />
              <NavLink onClick={() => {
                click && setClick(!click)
              }} to={i18n.resolvedLanguage + t('navbar.routes.home')} className={({ isActive }) =>
                isActive ? 'selectedLink' : ''
              }>
                {t('navbar.mainLinks.home')}
              </NavLink>
              <div className='dropdownLink'>
                <div className="productsLink" ><a>
                  {t('navbar.mainLinks.products')}
                </a>
                  <img src={arrowDown} alt="arrowDown Icon" /></div>
                <DropdownComponent products closeMobile={closeMobile} setCloseMobile={setCloseMobile} />
              </div>
              <div className='dropdownLink' id="servicesDropdown">
                <div className="productsLink" ><a>
                  {t('navbar.mainLinks.services')}
                </a>
                  <img src={arrowDown} alt="arrowDown Icon" /></div>
                <DropdownComponent services closeMobile={closeMobile} setCloseMobile={setCloseMobile} />
              </div>
              <NavLink onClick={() => {
                click && setClick(!click)
              }} to={i18n.resolvedLanguage + t('navbar.routes.about')} className={({ isActive }) =>
                isActive ? 'selectedLink' : ''
              }>
                {t('navbar.mainLinks.about')}
              </NavLink>
              <NavLink onClick={() => {
                click && setClick(!click)
                setCurrentVideo(videos[0].id)
              }} to={i18n.resolvedLanguage + t('navbar.routes.references')} className={({ isActive }) =>
                isActive ? 'selectedLink' : ''
              }>
                {t('navbar.mainLinks.references')}
              </NavLink>
              <div className='dropdownLink langChange'>
                <div className="productsLink " ><a>
                  <Flag size='m' code={i18n.resolvedLanguage === 'en' ? 'GB-UKM' : 'HR'} />
                </a>
                </div>
                <img src={arrowDown} alt="arrowDown Icon" />
                <DropdownComponent lang />
              </div>

              {/* <a href="https://pvstore.hr/" target="_blank" rel="noopener noreferrer">
              {t('navbar.mainLinks.shop')}
            </a> */}
              {/* <NavLink onClick={() => {
            click && setClick(!click)
          }} to="i18n.resolvedLanguage+/login" className={({ isActive }) =>
            isActive ? 'selectedLink' : ''
          }>
            {t('navbar.mainLinks.login')}
          </NavLink> */}

              <ButtonComponent className='contactButton' ghost onClick={() => { setOpenModal(true); click && setClick(!click) }}>{t('navbar.mainLinks.contact')}<img src={mailIcon} style={{}} alt="Mail Icon" /> </ButtonComponent>

            </div>
          </div>
          {/* Desktop navbar */}
          <div className={click ? 'hideLinks' : 'links'}>
            <div className="topLinks">
              <div className="leftContent"><NavLink onClick={() => {
                click && setClick(!click)
              }} to={i18n.resolvedLanguage + t('navbar.routes.home')} className="navbar__logo">
                <img src={logo} className='logo' alt='Logo image' />
              </NavLink>
                <SearchBarComponent clickSearch={clickSearch} setClickSearch={setClickSearch} />
              </div>
              <div className="rightContent"> <ButtonComponent className='cartButton' onClick={() => { toggleCartExpansion(); }}><img src={cartIcon} style={{}} alt="Cart Icon" />
                {cart.length ? <ParagraphComponent>{cart.length}</ParagraphComponent> : ''}
              </ButtonComponent>
                <ButtonComponent className='contactButton' ghost onClick={() => { setOpenModal(true); click && setClick(!click) }}>{t('navbar.mainLinks.contact')} <img src={mailIcon} style={{}} alt="Mail Icon" /> </ButtonComponent></div>
            </div>
            <div className="mainLinks">
              <NavLink to={i18n.resolvedLanguage + t('navbar.routes.home')} onClick={() => {
                click && setClick(!click)
              }} className={({ isActive }) =>
                isActive ? 'selectedLink' : ''
              }>
                {t('navbar.mainLinks.home')}
              </NavLink>
              <div className='dropdownLink'>
                <a>
                  {t('navbar.mainLinks.products')}
                </a>
                <img src={arrowDown} alt="arrowDown Icon" />
                <DropdownComponent products closeMobile={closeMobile} setCloseMobile={setCloseMobile} />
              </div>
              <div className='dropdownLink'>
                <a>
                  {t('navbar.mainLinks.services')}
                </a>
                <img src={arrowDown} alt="arrowDown Icon" />
                <DropdownComponent services={true} closeMobile={closeMobile} setCloseMobile={setCloseMobile} />
              </div>
              <NavLink onClick={() => {
                click && setClick(!click)
              }} to={i18n.resolvedLanguage + t('navbar.routes.about')} className={({ isActive }) =>
                isActive ? 'selectedLink' : ''
              }>
                {t('navbar.mainLinks.about')}
              </NavLink>
              <NavLink onClick={() => {
                click && setClick(!click)
                setCurrentVideo(videos[0].id)
              }} to={i18n.resolvedLanguage + t('navbar.routes.references')} className={({ isActive }) =>
                isActive ? 'selectedLink' : ''
              }>
                {t('navbar.mainLinks.references')}
              </NavLink>
              <div className='dropdownLink'>
                <a>
                  <Flag size='m' code={i18n.resolvedLanguage === 'en' ? 'GB-UKM' : 'HR'} />
                </a>
                <img src={arrowDown} alt="arrowDown Icon" />
                <DropdownComponent lang />
              </div>
              {/* <a href="https://pvstore.hr/" target="_blank" rel="noopener noreferrer">
              {t('navbar.mainLinks.shop')}
            </a> */}
              {/* <NavLink onClick={() => {
            click && setClick(!click)
          }} to="i18n.resolvedLanguage+/login" className={({ isActive }) =>
            isActive ? 'selectedLink' : ''
          }>
            {t('navbar.mainLinks.login')}
          </NavLink> */}
            </div>

            {/* <ButtonComponent className='contactButton' ghost onClick={() => { setOpenEvalModal(true); click && setClick(!click) }}>ouhil<img src={mailIcon} style={{}} alt="Mail Icon" /> </ButtonComponent> */}
          </div>
          <div
            // style={{ display: 'contents' }} 
            className='navbarTopContent' ref={menuIconRef}>
            <img onClick={() => {
              setClick(!click)
            }} className={click ? 'menuIcon' : 'menuIcon change'} src={click ? require('../../assets/icons/closeMenu.png') : require('../../assets/icons/menu.png')} alt="Open Navbar Icon" />
            <NavLink onClick={() => {
              click && setClick(!click)
            }} to={i18n.resolvedLanguage + t('navbar.routes.home')} className="navbar__logo mobile-logo">
              <img src={logo} className='logo' alt='Logo image' />
            </NavLink>
            <ButtonComponent className='cartButton mobileCartButton' onClick={() => { toggleCartExpansion() }}><img src={cartIcon} style={{}} alt="Cart Icon" />{cart.length ? <ParagraphComponent>{cart.length}</ParagraphComponent> : ''} </ButtonComponent>
          </div>
        </nav>
      </div>
      <div className={`cartContainer ${toggleCart ? 'opacity' : ''}`}>
        <div className={`cartContent ${toggleCart ? 'expanded' : ''}`}>
          <div className="closeIconContainer">
            <img className='closeIcon' onClick={() => { toggleCartExpansion() }} src={closeIcon} alt="Close Icon" />
          </div>

          {isLargeDesktop ?
            <div className="cartContainerInner">
              <div className="topContent">
                <h2><SubtitleComponent large className='productTitle' white>{t('navbar.cart.title')}</SubtitleComponent></h2>
                {cart.length > 0 ? cart.map((value: Product) => {
                  if (value.quantity) {
                    totalPrice = totalPrice + value.price * value.quantity
                  }
                  return (
                    <div key={value.id} className="productRow">
                      <div className="productLeft">
                        <div className="productImage" onClick={() => { navigate("/" + i18n.language + t('navbar.routes.equipment') + "/" + value.ref, { state: { category: value.category, id:value.id } }); setSelectedProduct(value); toggleCartExpansion() }}>
                          <div className={`imgBackground ${value.image === '' && 'noImgBg'}`}> {value.image === '' ? '' : <img src={value.image} alt={`Product image of ${value.title}`} />}</div>
                        </div>
                        <div className="leftContent">
                          <div className="productTitle" onClick={() => { navigate("/" + i18n.language + t('navbar.routes.equipment') + "/" + value.ref, { state: { category: value.category, id:value.id } }); setSelectedProduct(value); toggleCartExpansion() }}>
                            <h2><ParagraphComponent className='productTitle'>{value.title}</ParagraphComponent></h2>
                            {value.ref && <h3><ParagraphComponent className='productRef'>{t('productsCard.ref')} {value.ref}</ParagraphComponent></h3>}
                          </div>
                          {/* <div className="productPrice">
                      <ParagraphComponent white>{value.price} &euro;</ParagraphComponent>
                    </div> */}
                          

                        </div>

                      </div>
                      <div className="bottomContent">
                            <div className="productQuantity">
                              <img onClick={() => { value.quantity && value.quantity >= 1 && changeProductQuantity(value.id, value.quantity - 1) }} src={minusIcon} />
                              <input type='number' onChange={(event) => { changeProductQuantity(value.id, Number(event.target.value)) }} value={value.quantity} max={value.stock} min={0} />
                              <img onClick={() => { value.quantity && value.stock && value.quantity < value.stock && changeProductQuantity(value.id, value.quantity + 1) }} src={plusIcon} />                          </div>
                            <div className="productRemove">
                              <ParagraphComponent onClick={() => { removeProductFromCart(value.id) }}>{t('navbar.cart.remove')}</ParagraphComponent>
                            </div>
                          </div>
                    </div>
                  )
                }) : <>
                  <ParagraphComponent>{t('navbar.cart.emptyCart')}</ParagraphComponent>
                  <ParagraphComponent>{t('navbar.cart.products')} <NavLink onClick={() => { toggleCartExpansion() }} to={i18n.resolvedLanguage + t('navbar.routes.equipment')}>{t('navbar.cart.solarProducts')}</NavLink> {t('navbar.cart.or')} <NavLink onClick={() => { toggleCartExpansion() }} to={i18n.resolvedLanguage + t('navbar.routes.chargers')}>{t('navbar.cart.EV')}</NavLink></ParagraphComponent>
                </>}
              </div>
              <div className="bottomContent">
                {
                  cart.length > 0 ?
                    <>
                      {/* <ParagraphComponent bold style={{ marginTop: '2rem' }}>{t('navbar.cart.total')} {totalPrice.toFixed(2).toString()} &euro; </ParagraphComponent> */}
                      <ButtonComponent style={{ marginTop: '1rem' }} onClick={() => { setOpenEnquiryModal(true) }}>{t('navbar.cart.sendEnquiry')}</ButtonComponent>
                    </> : ''
                }
              </div>

            </div>
            :
            <div className="cartContainerInner">
              <h2><SubtitleComponent large className='productTitle' white>{t('navbar.cart.title')}</SubtitleComponent></h2>

              <div className="topContent">
                {/* {cart.length > 0 && <div className='columnNames'>
                  <div className="productColumn"><ParagraphComponent bold>{t('navbar.cart.product')}</ParagraphComponent></div>
                  <div className="quantityColumn"><ParagraphComponent bold>{t('navbar.cart.quantity')}</ParagraphComponent></div>
                  <div className="removeColumn"></div>
                </div>} */}

                {cart.length > 0 ? cart.map((value: Product) => {
                  if (value.quantity) {
                    totalPrice = totalPrice + value.price * value.quantity
                  }
                  return (
                    <div key={value.id} className="productRow">
                      <div className="productLeft">
                        <div className="productImage" onClick={() => { navigate("/" + i18n.language + t('navbar.routes.equipment') + "/" + value.ref, { state: { category: value.category, id:value.id } }); setSelectedProduct(value); toggleCartExpansion() }}>
                          <div className={`imgBackground ${value.image === '' && 'noImgBg'}`}> {value.image === '' ? '' : <img src={value.image} alt={`Product image of ${value.title}`} />}</div>
                        </div>
                        <div className="leftContent">
                          <div className="productTitle" onClick={() => { navigate("/" + i18n.language + t('navbar.routes.equipment') + "/" + value.ref, { state: { category: value.category, id:value.id } }); setSelectedProduct(value); toggleCartExpansion() }}>
                            <h2><ParagraphComponent className='productTitle'>{value.title}</ParagraphComponent></h2>
                            {value.ref && <h3><ParagraphComponent className='productRef'>{t('productsCard.ref')} {value.ref}</ParagraphComponent></h3>}
                          </div>

                          {/* <div className="productPrice">
                        <ParagraphComponent white>{value.price} &euro;</ParagraphComponent>
                      </div> */}
                        </div>
                      </div>
                      <div className="productRight">
                        <div className="quantityColumn">
                          <div className="productQuantity">
                            <img onClick={() => { value.quantity && value.quantity >= 1 && changeProductQuantity(value.id, value.quantity - 1) }} src={minusIcon} />
                            <input type='number' onChange={(event) => { changeProductQuantity(value.id, Number(event.target.value)) }} value={value.quantity} max={value.stock} min={0} />
                            <img onClick={() => { value.quantity && value.stock && value.quantity < value.stock && changeProductQuantity(value.id, value.quantity + 1) }} src={plusIcon} />

                          </div>
                        </div>
                        
                      </div>
                      <div className="removeColumn">
                          <div className="productRemove">
                            <ParagraphComponent bold onClick={() => { removeProductFromCart(value.id) }}>{t('navbar.cart.remove')}</ParagraphComponent>
                          </div>
                        </div>
                    </div>
                  )
                }) : <>
                  <ParagraphComponent>{t('navbar.cart.emptyCart')}</ParagraphComponent>
                  <ParagraphComponent>{t('navbar.cart.products')} <NavLink onClick={() => { toggleCartExpansion() }} to={i18n.resolvedLanguage + t('navbar.routes.equipment')}>{t('navbar.cart.solarProducts')}</NavLink> {t('navbar.cart.or')} <NavLink onClick={() => { toggleCartExpansion() }} to={i18n.resolvedLanguage + t('navbar.routes.chargers')}>{t('navbar.cart.EV')}</NavLink></ParagraphComponent>
                </>}
              </div>
              <div className="bottomContent">
                {
                  cart.length > 0 ?
                    <>
                      {/* <ParagraphComponent bold style={{ marginTop: '2rem' }}>{t('navbar.cart.total')} {totalPrice.toFixed(2).toString()} &euro; </ParagraphComponent> */}
                      <ButtonComponent style={{ marginTop: '1rem' }} onClick={() => { setOpenEnquiryModal(true) }}>{t('navbar.cart.sendEnquiry')}</ButtonComponent>
                    </> : ''
                }
              </div>

            </div>
          }
        </div>
        <div ref={cartRef} className="cartContentBottom"></div>
      </div> </>
  )
}
export default withTranslation()(NavbarComponent);
