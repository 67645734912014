import { useEffect, useState } from 'react'
import { Product } from '../../../components/productCard/data';
import { sortProductsByStock } from '../Equipment';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../../components';
import useProductStore from '../../../store/productStore';
function Batteries() {

    const [currentPage, setCurrentPage] = useState(1)
    const { batteries, fetchBatteries, loading, error } = useProductStore();
    const [tempSolarProducts, setTempSolarProducts] = useState<Product[]>(batteries.products);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (batteries.products.length === 0) fetchBatteries(0)
    }, [])

    useEffect(() => {
        const sortedProducts = sortProductsByStock(batteries.products);
        setTempSolarProducts(sortedProducts);
    }, [batteries]);

    return (
        <div className="equipment" id="equipmentContainer">
            <Helmet>
                <title>{t('solarEquipment.meta4')}</title>
                <meta name="description" content={t('solarEquipment.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('solarEquipment.title4')}</TitleComponent></h1>
                <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>
                {loading ? <div className="productCardContainer">
                    <ProductCardSkeletonComponent />
                    <ProductCardSkeletonComponent />
                    <ProductCardSkeletonComponent />
                    <ProductCardSkeletonComponent />
                    <ProductCardSkeletonComponent />
                    <ProductCardSkeletonComponent />
                </div> : <>
                    <div className="productCardContainer">
                        {tempSolarProducts.length > 0 ? <ProductCardComponent data={tempSolarProducts} /> : <ParagraphComponent >{error?.message}</ParagraphComponent>}

                    </div>
                </>}
                {batteries.pagination.page_count && currentPage <= batteries.pagination.page_count - 1 ?
                    <ButtonComponent style={{ margin: '0 auto', display: 'block' }} onClick={() => { setCurrentPage(currentPage + 1); fetchBatteries(currentPage) }}>Load more</ButtonComponent>
                    : ''}
                {/* {batteries.pagination.total ? <ParagraphComponent style={{ color: 'white' }}>{tempSolarProducts.length} of {batteries.pagination.total} Products</ParagraphComponent> : ''} */}
            </ContainerComponent>
        </div>
    )
}

export default Batteries